<template>
<el-scrollbar class="apply-yiKe">
  <div class="apply-banner">
    <div class="apply-box">
      <div class="apply-box-title">
        申请试用软件
      </div>
      <span class="hint">请填写您的基本信息，我们将很快安排工作人员联系您。</span>
      <el-form :model="applyForm" :rules="applyRules" ref="applyForm">
        <el-form-item prop="name">
          <el-input v-model="applyForm.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item prop="apply_platform">
          <el-input v-model="applyForm.apply_platform" placeholder="学校/机构"></el-input>
        </el-form-item>
        <el-form-item prop="position">
          <el-input v-model="applyForm.position" placeholder="职位"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="applyForm.phone" placeholder="手机"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="applyForm.email" placeholder="邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="demand">
          <el-input v-model="applyForm.demand" type="textarea" :rows="4" placeholder="我们想更多的了解您的需求，以便更好的为您推荐匹配的服务，请在此填写您的需求。"></el-input>
        </el-form-item>
        <el-form-item style="text-align: center;margin-bottom: 0;padding-top: 18px">
          <el-button class="submit-btn" @click="submitForm('applyForm')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
  <div class="apply-background">
    <div class="apply-content">
<!--      <div class="apply-content-icon">-->
<!--        <i @click="lookDialog = true" class="iconfont">&#xe611;</i>-->
<!--      </div>-->
    </div>
<!--    <el-dialog :visible.sync="lookDialog" width="1145px" custom-class="look-box"-->
<!--               @opened="openLookList" :close-on-click-modal="false">-->
<!--      <div class="close-btn" @click="lookDialog = false"><i class="iconfont">&#xe61b;</i></div>-->
<!--      <div class="look-box-title">-->
<!--        <i class="iconfont">&#xe611;</i>-->
<!--        <span>申请人列表</span>-->
<!--      </div>-->
<!--      <el-table :data="proposerList" style="width: 100%; margin-top: 28px; flex: 1;" height="1%" size="medium"-->
<!--                :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#333333', background: '#F2F2F2',fontSize: '18px'}"-->
<!--                :cell-style="{fontSize: '18px',color: '#666666',height: '80px'}">-->
<!--        <el-table-column prop="name" align="center" label="姓名">-->
<!--          <template slot-scope="scope">-->
<!--            <span class="text-overflow">{{scope.row.name}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="apply_platform" align="center" label="学校/机构">-->
<!--          <template slot-scope="scope">-->
<!--            <span class="text-overflow">{{scope.row.apply_platform}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="position" align="center" label="职位">-->
<!--          <template slot-scope="scope">-->
<!--            <span class="text-overflow">{{scope.row.position}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="phone" align="center" label="手机"></el-table-column>-->
<!--        <el-table-column prop="email" align="center" label="邮箱">-->
<!--          <template slot-scope="scope">-->
<!--            <span class="text-overflow">{{scope.row.email}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--      <el-pagination class="pager-center" style="margin-top: 36px;"-->
<!--                     :current-page="proposerPages.currentPageNum"-->
<!--                     :page-size="proposerPages.eachPageNum"-->
<!--                     :total="proposerPages.total"-->
<!--                     layout="prev, pager, next, jumper"-->
<!--                     @current-change="proposerCurrentChange">-->
<!--      </el-pagination>-->
<!--    </el-dialog>-->
    <OfficialFooter style="background: rgba(3,3,47,0.85)"/>
  </div>
</el-scrollbar>
</template>

<script>
import OfficialFooter from '@/components/OfficialFooter';
import {getApplyList, addApplyList} from '@/utils/apis';
export default {
  name: "applyTry",
  components:{
    OfficialFooter
  },
  data(){
    var isMobileNumber= (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    };
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    };
    return {
      applyForm:{
        name:'',
        apply_platform:'',
        position:'',
        phone:'',
        email:'',
        demand:''
      },
      applyRules:{
        name:[
          { required: true, message: '请输入申请人名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字', trigger: 'blur' }
        ],
        apply_platform:[
          { required: true, message: '请输入申请人所属平台', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字', trigger: 'blur' }
        ],
        position:[
          { required: true, message: '请输入申请人职位', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入申请人电话', trigger: 'blur' },
          { validator:isMobileNumber,trigger: 'blur' }
        ],
        email:[
          { required: true, message: '请输入申请人邮箱', trigger: 'blur' },
          { validator:checkEmail,trigger: 'blur' }
        ],
      },
      lookDialog:false,
      proposerList:[],
      proposerPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    }
  },
  methods:{
    openLookList(){
      let params = {
        type:1,
        paging:1,
        page:this.proposerPages.currentPageNum,
        pageSize:this.proposerPages.eachPageNum
      }
      getApplyList({params}).then((res)=>{
        this.proposerList = res.data.list;
        this.proposerPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    submitForm(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('name', this.applyForm.name);
          formData.append('apply_platform', this.applyForm.apply_platform);
          formData.append('position', this.applyForm.position);
          formData.append('phone', this.applyForm.phone);
          formData.append('email', this.applyForm.email);
          formData.append('demand', this.applyForm.demand);
          addApplyList(formData).then((res)=>{
            this.$message({
              message:res.msg,
              type:'success',
              duration:1000,
              onClose:()=>{
                this.$refs.applyForm.resetFields()
              }
            })
          }).catch((err)=>{
            console.log('err', err)
          })
        } else {
          return false
        }
      })
    },
    proposerCurrentChange(val){
      this.proposerPages.currentPageNum = val;
      this.openLookList();
    }
  }
}
</script>

<style scoped lang="scss">
.apply-yiKe{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  ::v-deep.el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .apply-banner{
    position: relative;
    width: 100%;
    height: 476px;
    background: url("./../../../assets/images/applyTry/apply-bj.jpg") no-repeat;
    background-size: cover;
    .apply-box{
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 790px;
      top: 160px;
      left: calc(50% - 395px);
      background: linear-gradient(183deg, #FFFFFF 0%, #FFFFFF 100%);
      box-shadow: 0px 0px 27px 2px rgba(3, 1, 70, 0.07);
      border-radius: 6px;
      box-sizing: border-box;
      padding: 40px;
      .apply-box-title{
        color: #333333;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        line-height: 1;
        box-sizing: border-box;
        border-bottom: 1px solid #DDDDDD;
        padding-bottom: 38px;
      }
      .hint{
        color: #FF0000;
        font-size: 18px;
        padding: 15px 0;
        line-height: 1;
      }
      ::v-deep.el-form .el-form-item .el-form-item__content{
        .el-input{
          font-size: 18px;
          position: relative;
          &:before{
            content: '*';
            line-height: 1;
            font-size: 18px;
            color: #FF0000;
            position: absolute;
            left: 10px;
            top: 20px;
          }
          .el-input__inner{
            background: #F2F2F2;
            height: 60px;
            padding: 0 24px;
            //color: #999999;
          }
        }
        .el-textarea{
          .el-textarea__inner{
            font-size: 18px;
            background-color: #F2F2F2;
          }
        }
      }
    }
  }
  .apply-background{
    width: 100%;
    height: 100%;
    background-image: url("./../../../assets/images/about/lift-hand.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 100%;
    background-color: #fff;
    .apply-content{
      height: 640px;
      //background: rgba(255,255,255,0.1);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .apply-content-icon{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        i{
          cursor: pointer;
          display: inline-block;
          width: 60px;
          height: 60px;
          background: #F36F21;
          color: #fff;
          border-radius: 50%;
          font-size: 32px;
          text-align: center;
          line-height: 60px;
          margin-bottom: 40px;
        }
      }
    }
  }
}
.submit-btn{
  padding: 10px 45px;
  background: #F36F21;
  border-color: #F36F21;
  color: #fff;
  font-size: 18px;
  &:focus,
  &:hover{
    background: #F28F21;
    border-color: #F28F21;
  }
}
::v-deep .look-box{
  height: 80%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(183deg, #FFFFFF 0%, #FFFFFF 100%);
  box-shadow: 0px 0px 27px 2px rgba(3, 1, 70, 0.07);
  border-radius: 6px;
  .close-btn{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: -60px;
    width: 60px;
    height: 60px;
    background: #F36F21;
    text-align: center;
    border-radius: 0 10px 10px 0;
    i{
      color: #fff;
      font-size: 26px;
      line-height: 60px;
    }
  }
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    flex: 1;
    height: 1%;
    padding: 40px 20px 30px;
    display: flex;
    flex-direction: column;
    .look-box-title{
      text-align: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #DDDDDD;
      i{
        color: #F36F21;
        font-size: 32px;
        margin-right: 30px;
      }
      span{
        color: #333333;
        font-size: 24px;
      }
    }
  }
}
</style>